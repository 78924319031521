import * as THREE from 'three';
import { loadFbx, textureLoader, loadTexture, loadGltf, loadVideo } from './load.js';
import { textureLists, materialLists } from './model.config.js';

export const getModel = async ({ loadingProgress } = {}) => {
  // const sceneGroup = await loadFbx('assets/models/exhibition/model/scene-final.fbx', {
  //   onProgress: (p) => {},
  // });

  //scene-final-1.gltf
  const sceneGroup = await loadGltf('assets/models/exhibition/model/scene-0418.gltf', {
    onProgress: (p) => {},
  });

  const phoneGroup = await loadFbx('assets/models/exhibition/model/phone.fbx', {
    onProgress: (p) => {},
  });

  const sampleGroup = await loadFbx('assets/models/exhibition/model/sample.fbx', {
    onProgress: (p) => {},
  });

  const texLoader = textureLoader('assets/models/exhibition/material/map', {
    onProgress: (p) => {
      loadingProgress(p);
    },
  });

  const loadTex = (src) => loadTexture(src, texLoader);

  const texLists = { ...textureLists };

  await Promise.all(
    Object.keys(textureLists).map(async (key) => {
      const tex = await loadTex(texLists[key]);
      texLists[key] = tex;
    }),
  );

  const screen1Video = document.getElementById('screen1');
  const screen3Video = document.getElementById('screen3');

  screen1Video.play().catch((e) => {});
  screen3Video.play().catch((e) => {});

  const screen1VideoTex = loadVideo(screen1Video);
  const screen3VideoTex = loadVideo(screen3Video);

  const materials = materialLists(texLists);

  ['ant', 'glassEnv', 'lake'].forEach((v) => {
    const texture = texLists[v];
    texture.mapping = THREE.EquirectangularReflectionMapping;
    texture.minFilter = THREE.LinearMipmapLinearFilter;
    texture.magFilter = THREE.NearestFilter;
  });

  sceneGroup.traverse((child) => {
    if (child.isMesh) {
      const material = materials[child.name];
      if (material) {
        const currentMaterial = Object.assign(child.material, material);

        if (child.name === '016_screen03') {
          // material.map = screen3VideoTex;
          currentMaterial.emissiveMap = screen3VideoTex;
        }

        if (child.name === '014_screen01') {
          // material.map = screen1VideoTex;
          currentMaterial.emissiveMap = screen1VideoTex;
        }

        child.material = new THREE.MeshPhysicalMaterial({
          ...currentMaterial,
          //
        });
      }
    }
  });

  phoneGroup.traverse((child) => {
    if (child.isMesh) {
      child.material = new THREE.MeshPhysicalMaterial({
        color: new THREE.Color(0xffffff),
        emissive: new THREE.Color(0x787878),
        reflectivity: 0.5,
        roughness: 1,
        map: texLists.phone,
        emissiveMap: texLists.phone,
        envMap: texLists.ant,
      });
    }
  });

  sampleGroup.traverse((child) => {
    if (child.isMesh) {
      child.material = new THREE.MeshPhysicalMaterial({
        color: new THREE.Color(0xddf1fb),
        emissive: new THREE.Color(0x3c3c3c),
        reflectivity: 0.5,
        roughness: 0,
        map: texLists.sample,
        emissiveMap: texLists.sample,
        lightMap: texLists.lake,
      });
    }
  });

  const sphereGeometry = new THREE.SphereGeometry(1, 32, 16);
  const material = new THREE.MeshPhysicalMaterial({
    color: new THREE.Color(0x000000),
    emissive: new THREE.Color(0xffffff),
    reflectivity: 0.5,
    roughness: 1,
    emissiveMap: texLists.lake,
    side: THREE.BackSide,
  });
  const wrapBallGroup = new THREE.Mesh(sphereGeometry, material);
  wrapBallGroup.scale.set(40, 40, 40);

  return {
    sceneGroup,
    phoneGroup,
    sampleGroup,
    wrapBallGroup,
  };
};
