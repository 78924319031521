import * as THREE from 'three';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { Reflector } from 'three/examples/jsm/objects/Reflector.js';

export const getBloomComposer = ({ T, canvas, dpr, BLOOM_SCENE }) => {
  const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
  const materials = {};
  const bloomLayer = new THREE.Layers();

  bloomLayer.set(BLOOM_SCENE);

  const darkenNonBloomed = (obj) => {
    if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
      materials[obj.uuid] = obj.material;
      obj.material = darkMaterial;
    }
  };

  const restoreMaterial = (obj) => {
    if (materials[obj.uuid]) {
      obj.material = materials[obj.uuid];
      delete materials[obj.uuid];
    }
  };

  const renderScene = new RenderPass(T.scene, T.camera);

  const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    1.5,
    0.4,
    0.85,
  );
  bloomPass.threshold = 0;
  bloomPass.strength = 0.45;
  bloomPass.radius = 0.5;

  const bloomComposer = new EffectComposer(T.renderer);
  bloomComposer.renderToScreen = false;
  bloomComposer.setSize(canvas.value.width, canvas.value.height);
  bloomComposer.addPass(renderScene);
  bloomComposer.addPass(bloomPass);

  const finalPass = new ShaderPass(
    new THREE.ShaderMaterial({
      uniforms: {
        baseTexture: { value: null },
        bloomTexture: { value: bloomComposer.renderTarget2.texture },
      },
      vertexShader: `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
          `,
      fragmentShader: `
            uniform sampler2D baseTexture;
            uniform sampler2D bloomTexture;
            varying vec2 vUv;
            void main() {
              gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
            }
          `,
      defines: {},
    }),
    'baseTexture',
  );
  finalPass.needsSwap = true;

  const finalComposer = new EffectComposer(T.renderer);
  finalComposer.setSize(canvas.value.width * dpr, canvas.value.height * dpr);
  finalComposer.addPass(renderScene);
  finalComposer.addPass(finalPass);

  return { darkenNonBloomed, restoreMaterial, finalComposer, bloomComposer };
};
