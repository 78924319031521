import * as THREE from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
// import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';

export function outline(T) {
  const composer = new EffectComposer(T.renderer);

  const renderPass = new RenderPass(T.scene, T.camera);
  composer.addPass(renderPass);

  const outlinePass = new OutlinePass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    T.scene,
    T.camera,
  );

  outlinePass.edgeStrength = 10;
  outlinePass.edgeGlow = 2;
  outlinePass.edgeThickness = 2;
  outlinePass.pulsePeriod = 0;
  outlinePass.rotate = false;
  outlinePass.usePatternTexture = false;
  outlinePass.visibleEdgeColor = new THREE.Color(0xaad0ee);

  composer.addPass(outlinePass);

  const effectFXAA = new ShaderPass(FXAAShader);
  effectFXAA.uniforms['resolution'].value.set(
    1 / (window.innerWidth * 2),
    1 / (window.innerHeight * 2),
  );
  composer.addPass(effectFXAA);

  return { composer, outlinePass };
}
