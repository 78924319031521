import * as THREE from 'three';
import { Capsule } from 'three/examples/jsm/math/Capsule.js';

const KEY = ['KeyW', 'KeyA', 'KeyS', 'KeyD', 'Space'];
const SPEED = [15, 10];
const GRAVITY = 30;

/**
 * @param {HTMLCanvasElement} canvas
 * 更新 camera position
 * W A S D 移动
 * Space 跳
 */
function createPlayer(canvas, camera, worldOctree) {
  camera.rotation.order = 'YXZ';

  const keyStates = Object.fromEntries(KEY.map((key) => [key, false]));
  const velocity = new THREE.Vector3();
  const direction = new THREE.Vector3();

  // const player = new Capsule(new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 8, 0), 1);
  const player = new Capsule(new THREE.Vector3(0, 0.35, 0), new THREE.Vector3(0, 1.2, 0), 0.35);

  let onFloor = false;

  const updateCamera = (pos) => {
    camera.position.copy(pos);
  };

  const isLocked = () => !!document.pointerLockElement;

  const setKeyStates = (code, state) => {
    if (KEY.includes(code)) {
      keyStates[code] = state;
    }
  };

  const onMouseChanges = () => {
    document.body.onmousemove = (event) => {
      if (isLocked()) {
        camera.rotation.y -= event.movementX / 200;
        camera.rotation.x -= event.movementY / 500;
      }
    };
  };

  const setDirection = (/** @type {'forward' | 'side'} */ dir) => {
    camera.getWorldDirection(direction);
    direction.y = 0;
    direction.normalize();
    if (dir === 'side') {
      direction.cross(camera.up);
    }
    return direction;
  };

  const setVelocityByKey = (deltaTime) => {
    const speedDelta = deltaTime * SPEED[onFloor ? 0 : 1];

    if (keyStates['KeyW']) {
      velocity.add(setDirection('forward').multiplyScalar(speedDelta));
    }

    if (keyStates['KeyS']) {
      velocity.add(setDirection('forward').multiplyScalar(-speedDelta));
    }

    if (keyStates['KeyA']) {
      velocity.add(setDirection('side').multiplyScalar(-speedDelta));
    }

    if (keyStates['KeyD']) {
      velocity.add(setDirection('side').multiplyScalar(speedDelta));
    }

    if (onFloor) {
      if (keyStates['Space']) {
        velocity.y = 8;
      }
    }
  };

  const playerCollisions = () => {
    onFloor = false;
    const result = worldOctree.capsuleIntersect(player);
    if (result) {
      onFloor = result.normal.y > 0;
      if (!onFloor) {
        velocity.addScaledVector(result.normal, -result.normal.dot(velocity));
      }
      player.translate(result.normal.multiplyScalar(result.depth));
    }
  };

  const setVelocity = (deltaTime) => {
    let damping = Math.exp(-4 * deltaTime) - 1;
    if (!onFloor) {
      velocity.y -= GRAVITY * deltaTime;
      damping *= 0.1;
    }
    velocity.addScaledVector(velocity, damping);
    const deltaPosition = velocity.clone().multiplyScalar(deltaTime);
    player.translate(deltaPosition);

    playerCollisions();

    updateCamera(player.end);
  };

  const init = () => {
    onMouseChanges();
  };

  init();

  const move = (deltaTime) => {
    if (isLocked()) {
      setVelocityByKey(deltaTime);
      setVelocity(deltaTime);
    }
  };

  return { move, setKeyStates };
}

export { createPlayer };
