<template lang="pug">
#container
  .video-wrapper
    video(id='screen1' src='@/assets/exhibition/screen-1.mp4' muted autoplay loop) 
    video(id='screen3' src='@/assets/exhibition/screen-3.mp4' muted autoplay loop)

  .loading.full-s.flex-center(v-show='!isEnter')
    div(:style="{ 'opacity': isLoaded ? 0 : 1}") 
      div Loading... {{ parseInt(progress.toFixed(2) * 100, 10) }}%
    .btn.flex-center.enter-btn(@click='enter' :style="{ 'opacity': isLoaded ? 1 : 0}") 
      div 立即体验
  .main-wrapper.full-s(:style="{ 'opacity':  isEnter ? 1 : 0}")  
    canvas(ref='canvas')
    //- .tips(:style="style") {{ tipText }}
    img.fake-shot(v-show='isLocked' src='@/assets/shoot.svg')
    .f-display.btn.flex-center(v-show='isCapture && isLocked') 
      img(src='@/assets/exhibition/more.svg')
      .text 查看 {{ tipText }}
    .detail-pop(v-show='isPopShow' :class="{ 'opacity-bg': isDetailShow }")
      .continue.flex-center(v-show='!isDetailShow')
        .btn.flex-center(@click='enter') 继续体验
      .phone-detail.flex-center(v-show='isDetailShow') 
        img.close(@click='enter' src='@/assets/exhibition/close.svg')
        img.phone(
          :style="{'visibility': currentItem !== 0 ? 'unset' : 'hidden'}" 
          src='@/assets/exhibition/phone.png'
        )
        Phone(
          @progress='handlePhoneLoad' 
          v-if='isDetailShow || isPhoneLoaded' 
          :style="{'visibility': currentItem === 0 && isDetailShow ? 'unset' : 'hidden'}"
          :shouldPhoneRender='shouldPhoneRender'
          )
        .detail 
          .title OPPO Find X5 Pro 白瓷
          .desc 一帧影像，动用两块芯片
          .choose.flex 
            .item(
              :class="{'active': currentItem === val, 'disabled': val === 0 && phoneProgress < 1 }"
              v-for='val in [0, 1, 2, 3, 4]' 
              @click='chooseItem(val)'
              )
              img(:src='require(`@/assets/exhibition/choose-${val}.png`)')
              .phone-progress(v-show='val === 0')
                .inner(:style="{ 'transform': `scaleX(${phoneProgress})` }")
          .more.flex 
            a.overview 详细了解
            a.buy 立即购买

  .control-bar.flex(v-show='isEnter')
    .foot
      .f-b.w  W
      .f-bs.flex
        .f-b.a A
        .f-b.s S
        .f-b.d D
      .text 移动位置
    .eye
      img.mouse(src='@/assets/exhibition/mouse.svg')
      .text 移动镜头
</template>

<script>
import * as THREE from 'three';
import { Octree } from 'three/examples/jsm/math/Octree.js';
import { onMounted, ref, reactive, computed } from 'vue';
import { Creator } from './core/creator.js';
import { getModel } from './core/model.js';
import { createPlayer } from './core/player.js';
import { getBloomComposer } from './core/bloom.js';
import { antiAliasing } from './core/anti-aliasing.js';
import { outline } from './core/outline.js';
import { defineAsyncComponent } from 'vue';
// import Phone from './phone.vue';

const DPR = window.devicePixelRatio;
const IS_PLAYER = true;
const BLOOM_SCENE = 1;

export default {
  name: 'Exhibition',
  components: {
    Phone: defineAsyncComponent(() => import('./phone.vue')),
  },
  setup() {
    const canvas = ref(null);
    const progress = ref(0);
    const isLocked = ref(!IS_PLAYER);
    const isEnter = ref(false);
    const isCapture = ref(false);
    const isDetailShow = ref(false);
    const tipText = ref('tipText');
    const currentItem = ref(1);

    // 获取手机加载进度
    const phoneProgress = ref(0);
    // 手机是否执行render
    const shouldPhoneRender = ref(false);
    // 手机是否加载完成，用来避免 v-if 二次创建元素
    const isPhoneLoaded = ref(false);

    const style = reactive({
      opacity: 0,
      transform: `translateX(0) translateY(0)`,
    });

    const isLoaded = computed(() => {
      return progress.value >= 1;
    });

    const isPopShow = computed(() => {
      return isEnter.value && !isLocked.value;
    });

    const chooseItem = (v) => {
      if (v === 0 && !isPhoneLoaded.value) return;
      currentItem.value = v;
      shouldPhoneRender.value = v === 0;
    };

    const pointerLock = () => {
      if (!document.pointerLockElement && IS_PLAYER) {
        document.body.requestPointerLock();
      }
    };

    const cancelLock = () => {
      if (document.pointerLockElement && IS_PLAYER) {
        document.exitPointerLock();
      }
    };

    const enter = () => {
      pointerLock();
      isEnter.value = true;
      isDetailShow.value = false;
      shouldPhoneRender.value = false;
    };

    const handlePhoneLoad = (p) => {
      phoneProgress.value = p;
      if (p === 1) {
        isPhoneLoaded.value = true;
      }
    };

    onMounted(async () => {
      if (IS_PLAYER) {
        document.onpointerlockchange = () => {
          isLocked.value = !!document.pointerLockElement;
        };
      }

      const clock = new THREE.Clock();

      canvas.value.width = DPR * canvas.value.offsetWidth;
      canvas.value.height = DPR * canvas.value.offsetHeight;

      const T = new Creator(canvas.value, IS_PLAYER);
      const worldOctree = new Octree();

      // 模型
      const { sceneGroup, phoneGroup, sampleGroup, wrapBallGroup } = await getModel({
        loadingProgress: (p) => {
          progress.value = p;
        },
      });

      // group.scale.set(0.1, 0.1, 0.1);
      // const group = await getSimpleModel();
      worldOctree.fromGraphNode(sceneGroup);

      // 相机位置
      if (!IS_PLAYER) {
        T.camera.position.set(20, 0, 250);
      }

      // 灯光
      const setLight = () => {
        const hemisphereLight = new THREE.HemisphereLight(0x7a7a7a, 0x454545, 1);
        hemisphereLight.position.set(0, 10, 0);
        // const helper = new THREE.HemisphereLightHelper(hemisphereLight, 1);
        // T.scene.add(helper);
        T.scene.add(hemisphereLight);
      };

      // 手机模型
      const setPhone = () => {
        phoneGroup.scale.set(1, 1, 1);
        phoneGroup.position.set(0, 0.1, 0);
        wrapBallGroup.position.set(0, 0.1, 0);
        sampleGroup.position.set(0, 0.1, 0);
      };

      const modelGroup = new THREE.Group();
      modelGroup.add(sceneGroup, sampleGroup, wrapBallGroup, phoneGroup);
      T.scene.add(modelGroup);

      // 精灵 tips
      // const generateTips = () => {
      //   const tipTexture = new THREE.TextureLoader().load(require('@/assets/info.png'));
      //   const tipMaterial = new THREE.SpriteMaterial({ map: tipTexture });
      //   const tip0 = new THREE.Sprite(tipMaterial);
      //   tip0.name = 'tip0';
      //   tip0.position.set(1, 2, -1);
      //   const tip1 = new THREE.Sprite(tipMaterial);
      //   tip1.name = 'tip1';
      //   tip1.position.set(-3, 2, 5);
      //   T.scene.add(tip0, tip1);
      //   return [tip0, tip1];
      // };

      const { composer: outlineComposer, outlinePass } = outline(T);

      const rayCapture = (arr) => {
        const coords = new THREE.Vector2(0, 0).normalize();
        T.rayCaster.setFromCamera(coords, T.camera);
        const intersects = T.rayCaster.intersectObjects(arr, false);

        if (intersects.length) {
          const intersect = intersects[0];
          const { distance, object } = intersect;
          if (!/^(phone)/gi.test(object.name) || distance > 1.5) {
            return;
          }
          isCapture.value = true;
          outlinePass.selectedObjects = [object];

          const name = object.name;
          tipText.value = name;
          // style.transform = `translateX(${window.innerWidth /
          //   2}px) translateY(${window.innerHeight / 2}px)`;
          // style.opacity = isLocked.value ? 1 : 0;
        } else {
          isCapture.value = false;
          outlinePass.selectedObjects = [];
          // style.opacity = 0;
        }
      };

      // bloom
      // const bloomPass = () => {
      //   group.traverse((child) => {
      //     if (child.name === '06_glow') {
      //       child.layers.toggle(BLOOM_SCENE);
      //     }
      //   });

      //   const {
      //     darkenNonBloomed,
      //     restoreMaterial,
      //     bloomComposer,
      //     finalComposer,
      //   } = getBloomComposer({
      //     T,
      //     canvas,
      //     DPR,
      //     BLOOM_SCENE,
      //   });

      //   T.scene.traverse(darkenNonBloomed);
      //   bloomComposer.render();
      //   T.scene.traverse(restoreMaterial);
      //   finalComposer.render();
      // };
      setLight();
      setPhone();
      // const tips = generateTips();

      // let arrow;
      const player = IS_PLAYER ? createPlayer(canvas.value, T.camera, worldOctree) : undefined;

      document.onkeydown = (event) => {
        if (!isPopShow.value) {
          player.setKeyStates(event.code, true);

          if (event.code === 'KeyF' && isCapture.value) {
            isDetailShow.value = true;
            if (isPhoneLoaded.value && currentItem.value === 0) {
              shouldPhoneRender.value = true;
            }
            cancelLock();
          }
        }
      };

      document.onkeyup = (event) => {
        if (!isPopShow.value) {
          player.setKeyStates(event.code, false);
        }
      };

      // const { composer } = antiAliasing(T);

      const render = () => {
        if (!isPopShow.value) {
          console.log('debug -> scene render');
          rayCapture(phoneGroup.children[1].children);
          // 真.辅助准星
          // if (arrow) T.scene.remove(arrow);
          // arrow = new THREE.ArrowHelper(
          //   T.rayCaster.ray.direction,
          //   T.rayCaster.ray.origin,
          //   8,
          //   0xff0000,
          // );
          // T.scene.add(arrow);

          // bloomPass();
          if (IS_PLAYER) {
            const deltaTime = Math.min(0.05, clock.getDelta());
            player.move(deltaTime);
          } else {
            T.controls.update();
          }

          // T.renderer.render(T.scene, T.camera);
          // composer.render();
          outlineComposer.render();
        }
        window.requestAnimationFrame(render);
      };

      render();
    });

    return {
      canvas,
      progress,
      isLoaded,
      isLocked,
      isEnter,
      isCapture,
      isDetailShow,
      isPopShow,
      isPhoneLoaded,
      shouldPhoneRender,
      style,
      tipText,
      currentItem,
      phoneProgress,
      handlePhoneLoad,
      enter,
      chooseItem,
    };
  },
};
</script>

<style lang="stylus" scoped>
#container
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  background-color black

  .video-wrapper
    width 0
    height 0
    opacity 0

  .flex
    display flex

  .full-s
    position absolute
    top 0
    left 0
    width 100%
    height 100%

  .flex-center
    display flex
    align-items center
    justify-content center

  .loading
    background black
    font-size 40px
    font-weight 800
    color white
    flex-direction column
    z-index 2
    & > *
      transition opacity .5s

  .main-wrapper
    z-index 1
    opacity 0
    transition opacity .8s
    &.active
      opacity 1

  .btn
    width 150px
    font-size 14px
    height 50px
    border-radius 5px
    color black
    border 1px solid black
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)

  .enter-btn
    cursor pointer
    color white
    border 1px solid white

  .detail-pop
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    &.opacity-bg
      background alpha(#767881, 0.6)
  .phone-detail
    position relative
    text-align left
    height 100%
    color white

    .close
      position absolute
      top 30px
      right 30px
      width 32px
      height 32px
      cursor pointer
      z-index 9
    .phone
      position absolute
      top 50%
      left 50%
      transform translateX(-50% - 10px) translateY(-50%)
      width 612px
      height 582px
    .detail
      margin-left (1000 / 19.2)vw
      .title
        font-size 24px
      .desc
        font-size 16px
        margin-top 5px
      .choose
        margin 26px 0
        .phone-progress
          position absolute
          width 40px
          height 4px
          left 10px
          bottom 10px
          border-radius 4px
          background alpha(#000, 0.2)
          .inner
            width 40px
            height 4px
            background #63c55d
            border-radius 4px
            transform-origin left
        .item
          position relative
          width 60px
          height 60px
          margin-right 8px
          cursor pointer
          border-radius 8px
          &.active
            border 1px solid #63c55d
          &.disabled
            cursor not-allowed
          img
            width 60px
            height 60px
      .more
        & > *
          width 88px
          height 40px
          border-radius 4px
          display flex
          align-items center
          justify-content center
          cursor pointer
        .buy
          border-radius 4px
          margin-left 10px
          background-color #63c55d
  .continue
    position absolute
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    width 40vw
    height 40vh
    background alpha(#000, 0.5)
    border-radius 10px
    flex-direction column
    color white
    font-size 20px
    border 1px solid white
    .btn
      margin-top 20px
      cursor pointer
      z-index 4
      color white
      position relative
      top 0
      left 0
      transform translate(0)
      border 1px solid white

  .f-display
    top 50%
    transform translateX(-50% + 80px) translateY(-50% + 120px)
    z-index 5
    width 220px
    height 36px
    border none
    img
      width 100%
    .text
      position absolute
      color white
      left 42px
      top 9px
      font-size 14px

  .tips
    pointer-events none
    position absolute
    top 0
    left 0
    width 100px
    height 50px
    border-radius 5px
    background alpha(red, 0.4)
    display flex
    justify-content center
    align-items center
    font-size 14px
    color white
    z-index 2
    transition opacity .4s
    cursor none

  .fake-shot
    position absolute
    width 5vw
    height 5vw
    top calc(50vh - 2.5vw)
    left calc(50vw - 2.5vw)
    z-index 3

  .control-bar
    position fixed
    left 34px
    bottom 24px
    color white
    z-index 5
    font-size 14px
    align-items flex-end
    // mix-blend-mode difference
    .f-b
      width 32px
      height 32px
      border-radius 4px
      border 1px solid white
      // background alpha(#fff, 0.2)
      background alpha(#767881, 0.2)
      margin auto
      display flex
      align-items center
      justify-content center
      font-weight 700
    .f-bs
      margin-top 4px
      & > :nth-child(2)
        margin 0 4px
    .text
      margin-top 12px
    .eye
      margin-left 81px
      .mouse
        width 24px
        height 40px

  canvas
    width 100%
    height 100%
    z-index 1
</style>
