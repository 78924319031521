import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export class Creator {
  canvas = null;
  scene = null;
  camera = null;
  renderer = null;
  controls = null;
  rayCaster = null;

  constructor(canvas) {
    this.canvas = canvas;
    this.init();
  }

  init() {
    this.setScene();
    this.setCamera();
    this.setRenderer();
    this.setControls();
    this.setRayCaster();
  }

  setScene() {
    this.scene = new THREE.Scene();
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(
      50,
      window.innerWidth / window.innerHeight,
      0.01,
      1000,
    );
    this.camera.position.set(0, 0, 3);
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      depth: true,
      antialias: true,
      logarithmicDepthBuffer: true,
    });
    // this.renderer.setClearColor('#aaaaaa', 1.0);
  }

  setControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.listenToKeyEvents(window);
    this.controls.screenSpacePanning = false;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.2;
    this.controls.keyPanSpeed = 21;
    this.controls.keys = {
      LEFT: 'KeyA', //a
      UP: 'KeyW', // w
      RIGHT: 'KeyD', // d
      BOTTOM: 'KeyS', // s
    };
  }

  setRayCaster() {
    this.rayCaster = new THREE.Raycaster();
  }
}
