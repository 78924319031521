<template lang="pug">
#container
  canvas(ref='canvas' @click='click')
</template>

<script>
import * as THREE from 'three';
import { onMounted, ref, reactive } from 'vue';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

import { Creator } from './Creator.js';
import { loadFbx, loadGltf, loadTexture } from '../Exhibition/core/load';

export default {
  name: 'Exhibition',
  setup() {
    const BLOOM_SCENE = 1;
    // test
    const canvas = ref(null);
    const dpr = window.devicePixelRatio;
    const mouse = reactive({ x: 0, y: 0 });
    const click = (e) => {
      handleClick(e);
    };
    let handleClick = (e) => {};

    onMounted(async () => {
      canvas.value.width = dpr * canvas.value.offsetWidth;
      canvas.value.height = dpr * canvas.value.offsetHeight;

      const T = new Creator(canvas.value);
      // 模型

      const model = await loadFbx('assets/models/exhibition/model/scene.fbx', {
        onProgress: (p) => {},
      });

      const texLoader = new THREE.TextureLoader();

      const antwerp = await loadTexture('assets/models/exhibition/material/antwerp.png', texLoader);
      const phone = await loadTexture('assets/models/exhibition/material/phone.png', texLoader);
      const screen = await loadTexture('assets/models/exhibition/material/screen.png', texLoader);
      const wood = await loadTexture('assets/models/exhibition/material/wood.png', texLoader);

      antwerp.mapping = THREE.EquirectangularReflectionMapping;
      antwerp.minFilter = THREE.LinearMipmapLinearFilter;
      antwerp.magFilter = THREE.NearestFilter;

      const group = model;
      group.scale.set(0.1, 0.1, 0.1);

      T.scene.add(group);

      group.traverse((child) => {
        if (child.isMesh) {
          if (child.name === '01_matel') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xffffff),
              emissive: new THREE.Color(0x000000),
              roughness: 0.14,
              metalness: 1,
              envMap: antwerp,
            });
          }
          if (child.name === '02_topup') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0x121212),
              emissive: new THREE.Color(0x000000),
              roughness: 0.14,
              metalness: 0.92,
              envMap: antwerp,
            });
          }
          if (child.name === '03_glass') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0x000000),
              emissive: new THREE.Color(0x000000),
              roughness: 0,
              metalness: 0.08,
              transparent: true,
              opacity: 0.4,
              side: THREE.DoubleSide,
              envMap: antwerp,
            });
          }
          if (child.name === '04_wood') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xffffff),
              emissive: new THREE.Color(0x000000),
              roughness: 0,
              metalness: 0.22,
              map: wood,
              envMap: antwerp,
            });
          }
          if (child.name === '05_screen') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xffffff),
              emissive: new THREE.Color(0x616161),
              roughness: 1,
              metalness: 0,
              map: screen,
              emissiveMap: screen,
            });
          }
          if (child.name === '06_glow') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xffffff),
              emissive: new THREE.Color(0xffffff),
              roughness: 1,
              metalness: 0,
            });

            child.layers.toggle(BLOOM_SCENE);
          }
          if (child.name === '07_phone') {
            child.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xffffff),
              emissive: new THREE.Color(0xababab),
              roughness: 0,
              metalness: 0.58,
              map: phone,
              emissiveMap: phone,
            });
          }
        }
      });

      //相机位置
      T.camera.position.set(20, 0, 250);

      group.position.y = -20;

      const hemisphereLight = new THREE.HemisphereLight(0xc7f1ff, 0x403111, 1);

      hemisphereLight.position.x = 0;
      hemisphereLight.position.y = 10;
      hemisphereLight.position.z = 0;
      T.scene.add(hemisphereLight);

      const ambientLight = new THREE.AmbientLight(0x171717);
      T.scene.add(ambientLight);

      // // 精灵 tips
      // const tipTexture = new THREE.TextureLoader().load(require('@/assets/bubble/bunny.png'));
      // const tipMaterial = new THREE.SpriteMaterial({ map: tipTexture });
      // const tip = new THREE.Sprite(tipMaterial);
      // tip.scale.set(10, 10, 10);
      // tip.position.set(0, 100, 50);
      // T.scene.add(tip);

      // // rayCaster
      // handleClick = (e) => {
      //   e.preventDefault();
      //   mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      //   mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
      //   T.rayCaster.setFromCamera(mouse, T.camera);
      //   const intersects = T.rayCaster.intersectObject(tip, false);
      //   if (intersects.length > 0) {
      //     const selectedObject = intersects[0].object;
      //     T.camera.position.z = selectedObject.position.z;
      //     T.camera.position.y = selectedObject.position.y;
      //     T.camera.position.x = selectedObject.position.x;
      //   }
      // };

      const darkMaterial = new THREE.MeshBasicMaterial({ color: 'black' });
      const materials = {};
      const bloomLayer = new THREE.Layers();

      bloomLayer.set(BLOOM_SCENE);

      const darkenNonBloomed = (obj) => {
        if (obj.isMesh && bloomLayer.test(obj.layers) === false) {
          materials[obj.uuid] = obj.material;
          obj.material = darkMaterial;
        }
      };

      const restoreMaterial = (obj) => {
        if (materials[obj.uuid]) {
          obj.material = materials[obj.uuid];
          delete materials[obj.uuid];
        }
      };

      const renderScene = new RenderPass(T.scene, T.camera);

      const bloomPass = new UnrealBloomPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        1.5,
        0.4,
        0.85,
      );
      bloomPass.threshold = 0;
      bloomPass.strength = 0.45;
      bloomPass.radius = 0.5;

      const bloomComposer = new EffectComposer(T.renderer);
      bloomComposer.renderToScreen = false;
      bloomComposer.setSize(canvas.value.width, canvas.value.height);
      bloomComposer.addPass(renderScene);
      bloomComposer.addPass(bloomPass);

      const finalPass = new ShaderPass(
        new THREE.ShaderMaterial({
          uniforms: {
            baseTexture: { value: null },
            bloomTexture: { value: bloomComposer.renderTarget2.texture },
          },
          vertexShader: `
            varying vec2 vUv;
            void main() {
              vUv = uv;
              gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
          `,
          fragmentShader: `
            uniform sampler2D baseTexture;
            uniform sampler2D bloomTexture;
            varying vec2 vUv;
            void main() {
              gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
            }
          `,
          defines: {},
        }),
        'baseTexture',
      );
      finalPass.needsSwap = true;

      const finalComposer = new EffectComposer(T.renderer);
      finalComposer.setSize(canvas.value.width * dpr, canvas.value.height * dpr);
      finalComposer.addPass(renderScene);
      finalComposer.addPass(finalPass);

      // const planeGeometry = new THREE.PlaneBufferGeometry(1600, 1400);
      // const mirror = new Reflector(planeGeometry, {
      //   clipBias: 0.03,
      //   textureWidth: window.innerWidth * window.devicePixelRatio,
      //   textureHeight: window.innerHeight * window.devicePixelRatio,
      //   color: new THREE.Color(0x889999),
      // });
      // T.scene.add(planeGeometry);

      const render = () => {
        T.scene.traverse(darkenNonBloomed);
        bloomComposer.render();
        T.scene.traverse(restoreMaterial);
        finalComposer.render();

        T.controls.update();
        // T.renderer.render(T.scene, T.camera);
        window.requestAnimationFrame(render);
      };

      render();
    });

    return { canvas, click };
  },
};
</script>

<style lang="stylus" scoped>
#container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}
canvas {
  width: 100%;
  height: 100%;
}
</style>
